<template lang="pug">
div.d-flex.flex-column
  HeaderGrid(:name="name" :uid="uid" :dateRanger="dateRanger" :tvaModeButton="tvaModeButton" :addButton="addButton" :searchButton="searchButton" :tagguerButton="tagguerButton" :selectedRows="selectedRows" @onFilterTextBoxChanged="onFilterTextBoxChanged" @selectParamerterAction="selectParamerterAction" @clickAction="clickAction" :searchPlaceholder="searchPlaceholder" :archiveMode="archiveMode" :archive="archive" @refreshRow="refreshRow" :companyTypeId="companyTypeId" :indexSearch="indexSearch" :homepageMode="homepageMode" :homepage="homepage")
  .d-flex.w-100.h-100(style="overflow: hidden;")
    .text-center.flex-center.w-100(v-if='isLoadingCompaniesList || isLoadingCompaniesListArchived || !headerLoaded')
      .loading-bg-inner
        .loader
          .outer
          .middle
          .inner
      .mt-5
        br
        br
        br
        | Chargement de la liste des sociétés...
    sync-grid#overviewgrid(ref="overviewgrid" v-else :uid="uid" :gridData="dataFiltered" :name="name" :headerData="headerData" :selectionSettings="selectionSettings" :editSettings="editSettings" :allowGrouping="allowGrouping" @setSelectedRows="setSelectedRows" :tagguerButton="tagguerButton" :allowKeyboard="allowKeyboard" @actionBegin="actionBegin" @rowClicked="onRowClicked" @setTagsSelected="setTagsSelected")
  ejs-sidebar.default-sidebar.items-no-padding(v-show="isOpen" ref="sidebar" key="sidebar" :type="type" :position="position" :showBackdrop="showBackdrop" :enablePersistence="enablePersistence"  :closeOnDocumentClick="closeOnDocumentClick" :isOpen="isOpen")
    sidebar-company-content(ref="sidebarCompanyContent" key="sidebarCompanyContent" @closeSidebar="closeSidebar" @refreshGrid="refreshGrid")
</template>
<script>
import { mapGetters, mapActions } from "vuex";

import { formatCurrency } from "@/types/api-orisis/library/FormatOperations.ts";
import { setColumnsByObject } from "@/types/api-orisis/library/ObjectOperations.ts";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

import { MultiSelect } from "@syncfusion/ej2-dropdowns";
import { TextBox } from "@syncfusion/ej2-inputs";
import { Query } from "@syncfusion/ej2-data";
import { checkFunctionUser } from "@/auth/utils.ts";
import SyncGrid from "@/components/global/grid/Grid.vue";
import HeaderGrid from "@/components/global/grid/HeaderGrid.vue";

import SidebarCompanyContent from "@/components/directory/company/SidebarCompanyContent";
import { SidebarComponent } from "@syncfusion/ej2-vue-navigations";

export default {
  components: {
    SidebarCompanyContent,
    SyncGrid,
    HeaderGrid,
    "ejs-sidebar": SidebarComponent,
  },
  data() {
    return {
      homepageMode: true,
      homepage: false,
      indexSearch: 0,
      isOpen: false,
      position: "Right",
      type: "Push",
      enablePersistence: false,
      closeOnDocumentClick: true,
      showBackdrop: true,
      name: "company",
      uid: "companies",
      archive: false,
      headerData: [],
      headerLoaded: false,
      tabActive: "all",
      tagsSelected: [],
      selectedRows: [],
      detailTemplate: "activitiesTemplate",
      withIndicators: false,
      dateRanger: false,
      tvaModeButton: false,
      addButton: true,
      addButton2: false,
      addButton2Icon: "e-icons e-people",
      searchButton: true,
      tagguerButton: true,
      groupActionButton: false,
      groupActionButtonLabel: "",
      searchPlaceholder: "Rechercher une société",
      allowKeyboard: false,
      allowGrouping: true,
      archiveMode: true,
      editSettings: {
        allowEditing: true,
        allowAdding: false,
        allowDeleting: false,
        mode: "Dialog",
        titleEdit: "Modifier les indications de",
        titleField: "name",
      },
      selectionSettings: {
        type: "Multiple",
        enableSimpleMultiRowSelection: false,
      },
    };
  },
  computed: {
    ...mapGetters([
      "companyIndicatorsList",
      "companiesList",
      "companiesListArchived",
      "isLoadingCompaniesList",
      "isLoadingCompaniesListArchived",
    ]),
    companyTypeId() {
      return this.$route.params.id ? Number(this.$route.params.id) : null;
    },
    dataFiltered() {
      if (this.archive) {
        if (this.tagsSelected.length) {
          return this.$store.getters["companiesListArchived"].filter((elem) =>
            elem[this.name + "Tags"].find((tag) =>
              this.tagsSelected.includes(tag.id)
            )
          );
        } else {
          return this.$store.getters["companiesListArchived"].filter(
            (company) => company.companyType.id == this.$route.params.id
          );
        }
      } else {
        if (this.tagsSelected.length) {
          return this.$store.getters["companiesList"].filter((elem) =>
            elem[this.name + "Tags"].find((tag) =>
              this.tagsSelected.includes(tag.id)
            )
          );
        } else {
          return this.$store.getters["companiesList"].filter(
            (company) => company.companyType.id == this.$route.params.id
          );
        }
      }
    },
  },
  async created() {
    if (checkFunctionUser(104, "read")) {
      await this.getCompanyIndicators({
        companyTypeId: this.companyTypeId,
      }).then((companyIndicators) => {
        if (companyIndicators.length) {
          this.withIndicators = true;
        } else {
          this.withIndicators = false;
        }
      });
    }
    await this.getData();
    this.getCollaborators({});
  },
  activated() {
    this.headerLoaded = false;
    this.getData();
  },
  methods: {
    ...mapActions([
      "getCompanyIndicators",
      "getCollaborators",
      "getCompanies",
      "getCompaniesArchived",
      "updateCompanyCompanyIndicator",
    ]),
    formatCurrency,
    setColumnsByObject,
    setHeaderData() {
      this.headerData = [
        {
          type: "checkbox",
          headerText: null,
          width: 30,
          maxWidth: 30,
          visible: true,
        },
        {
          type: "string",
          field: "color",
          headerText: "Couleur",
          width: 60,
          maxWidth: 60,
          allowFiltering: false,
          allowSorting: true,
          showInColumnChooser: true,
          template: "colorTemplate",
          visible: true,
          unavailableExport: true,
        },
        {
          type: "string",
          field: "code",
          headerText: "Code",
          width: 100,
          maxWidth: 120,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          visible: true,
        },
        {
          type: "string",
          field: "civility",
          filterTemplate: "civilityFilterTemplate",
          headerText: "Civilité",
          width: 100,
          maxWidth: 120,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          template: "civilityTemplate",
          visible: this.$route.params.id == 4 ? false : true, // interface = Fournisseurs ?
        },
        {
          type: "string",
          field: "name",
          headerText: "Nom",
          minWidth: 160,
          width: 160,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          visible: true,
          allowEditing: false,
        },
        {
          type: "string",
          field: "email",
          headerText: "E-mail",
          minWidth: 160,
          width: 160,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          visible: true,
        },
        {
          type: "string",
          field: "phoneNumber",
          headerText: "Téléphone",
          width: 120,
          minWidth: 120,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          visible: true,
        },
        {
          type: "string",
          field: "secondaryPhoneNumber",
          headerText: "Tél secondaire",
          width: 120,
          minWidth: 120,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          visible: true,
        },
        {
          type: "string",
          field: "companyTags",
          headerText: "Tags",
          width: 200,
          minWidth: 200,
          allowFiltering: false,
          allowSorting: false,
          showInColumnChooser: true,
          visible: true,
          clipMode: "EllipsisWithTooltip",
          template: "tagsTemplate",
          unavailableExport: true,
        },
        {
          type: "number",
          field: "collaboratorId",
          headerText: "Suivi par",
          width: 160,
          minWidth: 160,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          template: "collaboratorTemplate",
          visible: true,
          filterTemplate: "collaboratorFilterTemplate",
        },
      ];
    },
    getData() {
      this.getCompanies({ withIndicators: this.withIndicators }).then(
        async (res) => {
          this.setHeaderData();
          if (res.length) {
            await this.setColumnsByObject(res[0], null, this);
            this.indexSearch++;
          }

          if (this.companyIndicatorsList.length > 0) {
            let headerCompanyIndicator = {
              headerText: "Indicateurs",
              customCss: "text-primary",
              customAttributes: { class: "text-primary" },
              columns: [],
            };
            for (
              let index = 0;
              index < this.companyIndicatorsList.length;
              index++
            ) {
              const element = this.companyIndicatorsList[index];
              let elementEditor;
              let _this = this;
              // { label:"Checkbox", id:0},{ label:"Quantité", id:1},{ label:"Montant", id:2}
              headerCompanyIndicator.columns.push({
                type:
                  element.typeField == 11
                    ? "boolean"
                    : element.typeField == 1 ||
                      element.typeField == 2 ||
                      element.typeField == 3
                    ? "number"
                    : element.typeField == 7 || element.typeField == 8
                    ? "date"
                    : "string",
                id: element.id,
                field: "companyIndicator_" + element.id,
                label: element.label,
                headerText: element.label,
                filter: { type: "Excel" },
                fieldIndicator: true,
                format:
                  element.typeField == 7
                    ? "yMd"
                    : element.typeField == 8
                    ? "d/M/y hh:mm"
                    : null,
                width: 160,
                minWidth: 160,
                allowEdit: true,
                allowFiltering: true,
                allowSorting: true,
                showInColumnChooser: true,
                visible: true,
                // template: ,
                textAlign:
                  element.typeField == 1 ||
                  element.typeField == 2 ||
                  element.typeField == 3
                    ? "right"
                    : element.typeField == 7 ||
                      element.typeField == 8 ||
                      element.typeField == 11
                    ? "center"
                    : null,
                valueAccessor:
                  element.typeField == 11
                    ? function (field, data, column) {
                        return data[field] ? "Oui" : "Non";
                      }
                    : element.typeField == 2
                    ? function (field, data, column) {
                        return data[field] ? formatCurrency(data[field]) : "--";
                      }
                    : null,
                edit:
                  element.typeField == 9
                    ? {
                        params: {
                          allowFiltering: true,
                          dataSource: element.options
                            ? element.options.split(";")
                            : [],
                          query: new Query(),
                          sortOrder: "None",
                          actionComplete: () => false,
                        },
                      }
                    : element.typeField == 10
                    ? {
                        dataSource: element.options
                          ? element.options.split(";")
                          : [],
                        create: function () {
                          elementEditor = document.createElement("input");
                          return elementEditor;
                        },
                        destroy: function () {
                          _this["companyIndicator_" + element.id].destroy();
                        },
                        read: function () {
                          return _this[
                            "companyIndicator_" + element.id
                          ].value.join(";");
                        },
                        write: function (args) {
                          let defaultValue = args.rowData[args.column.field]
                            ? args.rowData[args.column.field].split(";")
                            : [];
                          _this["companyIndicator_" + element.id] =
                            new MultiSelect({
                              value: defaultValue,
                              placeholder: args.column.headerText,
                              dataSource: element.options
                                ? element.options.split(";")
                                : [],
                              floatLabelType: "Always",
                              mode: "Box",
                            });
                          _this["companyIndicator_" + element.id].appendTo(
                            elementEditor
                          );
                        },
                      }
                    : element.typeField == 5
                    ? {
                        dataSource: element.options
                          ? element.options.split(";")
                          : null,
                        create: function () {
                          elementEditor = document.createElement("input");
                          return elementEditor;
                        },
                        destroy: function () {
                          _this["companyIndicator_" + element.id].destroy();
                        },
                        read: function () {
                          return _this["companyIndicator_" + element.id].value;
                        },
                        write: function (args) {
                          _this["companyIndicator_" + element.id] = new TextBox(
                            {
                              value: args.rowData[args.column.field],
                              floatLabelType: "Always",
                              placeholder: args.column.headerText,
                              multiline: true,
                            }
                          );
                          _this["companyIndicator_" + element.id].appendTo(
                            elementEditor
                          );
                        },
                      }
                    : null,
                editType:
                  element.typeField == 11
                    ? "booleanedit"
                    : element.typeField == 1 ||
                      element.typeField == 2 ||
                      element.typeField == 3
                    ? "numericedit"
                    : element.typeField == 9 || element.typeField == 10
                    ? "dropdownedit"
                    : element.typeField == 5
                    ? "textarea"
                    : element.typeField == 7
                    ? "datepickeredit"
                    : element.typeField == 8
                    ? "datetimepickeredit"
                    : "stringedit",
              });
            }
            this.headerData.push(headerCompanyIndicator);
          }
          this.headerLoaded = true;
        }
      );
    },
    async actionBegin(args) {
      if (args.requestType === "beginEdit" || args.requestType === "add") {
        for (
          var i = 0;
          i < this.$refs.overviewgrid.$refs.overviewgrid.getColumns().length;
          i++
        ) {
          if (
            !this.$refs.overviewgrid.$refs.overviewgrid.getColumns()[i]
              .fieldIndicator
          ) {
            this.$refs.overviewgrid.$refs.overviewgrid.getColumns()[
              i
            ].visible = false;
          }
        }
      }

      if (args.requestType === "save" || args.requestType == "delete") {
        for (
          var i = 0;
          i < this.$refs.overviewgrid.$refs.overviewgrid.getColumns().length;
          i++
        ) {
          if (
            !this.$refs.overviewgrid.$refs.overviewgrid.getColumns()[i]
              .fieldIndicator
          ) {
            this.$refs.overviewgrid.$refs.overviewgrid.getColumns()[
              i
            ].visible = true;
          }
        }
      }

      if (args.requestType === "save" && args.action === "edit") {
        // On gère les indemnités
        let companyIndicators = {};
        for (const cle in args.data) {
          // const valeur = args.data[cle];
          if (cle.startsWith("companyIndicator_")) {
            const companyIndicatorValue = args.data[cle];
            const companyIndicatorId = parseInt(cle.substring(17));
            const companyIndicatorDetails = this.companyIndicatorsList.find(
              (el) => el.id == companyIndicatorId
            );
            if (companyIndicatorValue !== undefined) {
              companyIndicators[companyIndicatorId] = companyIndicatorValue;
            }
          }
        }
        if (companyIndicators !== {}) {
          await this.updateCompanyCompanyIndicator({
            companyId: args.data.id,
            companyIndicators: companyIndicators,
          });
        }
        // this.$refs.overviewgrid.$refs.overviewgrid.refresh()
      }
    },
    checkModule(moduleId) {
      return true;
    },
    onFilterTextBoxChanged(search) {
      this.$refs.overviewgrid.onFilterTextBoxChanged(search);
    },
    clickAction() {
      this.$router.push({
        path:
          "/directory/new-company/" +
          this.$route.params.name.toLowerCase() +
          "/" +
          this.$route.params.id,
      });
    },
    selectParamerterAction(args) {
      if (["csv", "xls", "print"].includes(args.item.type)) {
        this.$refs.overviewgrid.exportData(args.item.type);
      } else if (args.item.type == "archive") {
        this.getCompaniesArchived({});
        this.archive = true;
      } else if (args.item.type == "current") {
        this.getCompanies({ withIndicators: this.withIndicators });
        this.archive = false;
      } else {
        this.$refs.overviewgrid.$refs.overviewgrid.openColumnChooser();
      }
    },
    setSelectedRows(val) {
      this.selectedRows = val;
    },
    onRowClicked(event) {
      if (event.column) {
        if (!event.column.editType) {
          this.$nextTick(() => {
            this.isOpen = true;
            if (this.archive) {
              this.$refs["sidebarCompanyContent"].getCompanyArchivedDetails(
                event.rowData.id,
                1
              );
            } else {
              this.$refs["sidebarCompanyContent"].getCompanyDetails(
                event.rowData.id
              );
            }
            this.$refs.sidebar.toggle();
          });
        }
      }
    },
    refreshRow(val) {
      this.$refs.overviewgrid.$refs.overviewgrid.setRowData(val.id, val);
    },
    setTagsSelected(val) {
      this.tagsSelected = val;
    },
    refreshGrid(res) {
      this.$refs.overviewgrid.$refs.overviewgrid.refresh();
    },
    closeSidebar() {
      this.$refs.sidebar.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
.e-gridcontent {
  height: calc(100% - 127px) !important;
}
</style>
